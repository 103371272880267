* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif;
  transition: all 0.3s ease-in-out;
}
*:focus{
  outline: none;
}
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  padding-top: 73px;
}
.false-input {
  border-color: #FF385C !important;
}
.false-check::before {
  border-color: #FF385C !important;
}
/* Header */

.header {
  width: 100%;
  height: auto;
  padding: 16px 36px;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}
.logo-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  text-decoration: none;
}
.logo-text {
  font-size: 40px;
  line-height: 40px;
  width: auto;
  color: #FF385C;
  font-family: 'Dongle', sans-serif;
}
.user-block {
  padding: 5px 5px 5px 12px;
  border: 1px solid #DDDDDD;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  cursor: pointer;
  position: relative;
}
.google-block {
  padding: 5px;
}
.user-block:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.18);
}
.user {
  height: 28px;
  width: 28px;
  overflow: hidden;
  border-radius: 50%;
  background: #DDDDDD;
  margin-left: 10px;
  padding: 3px;
}
.google {
  padding: 0;
  background-color: #ffffff;
  margin-left: 0;
}
.user-circle {
  height: 22px;
  width: 22px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.user-circle img {
  width: 100%;
  height: auto;
}
.user-circle-google {
  height: 28px;
  width: 28px;
  background-color: #ffffff;
}
.user-dropdown {
  position: absolute;
  background: #ffffff;
  border: 1px solid #DDDDDD;
  z-index: 20;
  right: 36px;
  top: 66px;
  border-radius: 8px;
  width: 200px;
  overflow: hidden;
}
.user-dropdown-button {
  border: none;
  background: transparent;
  font-size: 18px;
  line-height: 18px;
  width: 100%;
  padding: 16px;
  text-align: start;
}
.user-dropdown-button:hover {
  background: #DDDDDD;
  opacity: 0.7;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .header {
    padding: 12px 36px;
  }
}

/* Modal */
.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.6);
  top: 0;
  left: 0;
  z-index: 21;
}
.modal-content {
  width: 100%;
  max-width: 420px;
  border-radius: 8px;
  background: #ffffff;
  padding: 20px;
}
.modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
}
.modal-close {
  background: transparent;
  border: none;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-close:hover {
  background: rgba(0,0,0,0.1);
  cursor: pointer;
}
.modal-input {
  padding: 8px 16px;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 4px;
}
.modal-input:focus {
  border: 1px solid #FF385C;
}
.modal-button {
  margin-top: 8px;
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  background: #FF385C;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.modal-button:hover {
  opacity: 0.7;
}
.password-input-wrapper {
  position: relative;
}
.password-visibility-toggle {
  position: absolute;
  right: 10px;
  top: calc(50% - 2px);
  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-visibility-toggle:hover {
  background: rgba(0,0,0,0.1);
  cursor: pointer;
}

/* Conteiner */
.conteiner {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: auto;
}
.conteiner-block {
  max-width: 1440px;
  padding: 0 120px;
  width: 100%;
  padding-top: 60px;
}
/* Main Page */

.main-title {
  font-size: 48px;
  max-width: 800px;
  letter-spacing: 5px;
  color: #333333;
  margin-bottom: 48px;
}
.main-list {
  max-width: 800px;
  width: 100%;
  font-size: 24px;
  letter-spacing: 2px;
  color: #FF385C;
  margin-bottom: 10px;
}
.button-block {
  margin-top: 38px;
  max-width: 800px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.get-start {
  font-size: 24px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 48px;
  border: none;
  background: #333333;
  cursor: pointer;
  text-decoration: none;
}
.get-start:hover {
  opacity: 0.8;
}

/* Dashboard */

.dashboard {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.form-logo {
  width: 600px;
  height: calc(100vh - 193px);
}
.form-logo h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.input-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
  position: relative;
}
.input-block span {
  font-size: 16px;
  margin-bottom: 8px;
}
.input {
  width: 100%;
  padding: 8px 8px;
  border: none;
  border: 2px solid rgba(51, 51, 51, 0.4);
  position: relative;
}
.input:focus {
  border: 2px solid rgba(51, 51, 51, 1);
}
.form-dropdown {
  width: 100%;
  padding: 8px 8px;
  border: none;
  border: 2px solid rgba(51, 51, 51, 0.4);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-dropdown-active {
  border: 2px solid rgba(51, 51, 51, 1);
}
.form-dropdown span {
  margin: 0;
}
.form-button {
  padding: 10px 32px;
  border: none;
  background: #333333;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  opacity: 1;
  text-decoration: none;
}
.form-button:hover {
  opacity: 0.8;
}
.form-dropdown-content {
  position: absolute;
  top: calc(100% + 5px);
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid rgba(51, 51, 51, 1);
  border-bottom: none;
  background: #ffffff;
  z-index: 1;
}
.form-dropdown-content button {
  padding: 10px 32px;
  border: none;
  border-bottom: 2px solid rgba(51, 51, 51, 1);
  background: #ffffff;
  cursor: pointer;
  color: #333333;
  font-weight: 500;
}
.form-dropdown-content button:hover {
  background: rgba(51, 51, 51, 0.4);
  color: #ffffff;
}
.necessarily {
  color: #FF385C;
  margin-left: 4px;
}


 /* Checkbox */
.checkbox {
  display: block;
  margin-bottom: 24px;
}

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
}

.checkbox label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #333333;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #333333;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.d-none-google {
  position: absolute;
  width: 40px;
  height: 40px;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
}

/* Pay */
.pay-block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.box {
  background: #0e1538;
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.box::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 140%;
  background: linear-gradient(#2D9CDB, #FF385C);
  animation: rotate 4s linear infinite;
  -webkit-animation: rotate 6s linear infinite;
}

.box::after {
  content: "";
  background-color: #ffffff;
  position: absolute;
  inset: 5px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.box__head,
.box__content,
.box__social {
  z-index: 1;
}

.box__head {
  width: auto;
  margin-bottom: 10px;
  font-size: 124px;
  height: 130px;
}

.box__content {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 40px;
}

.box__content > h2 {
  color: #FF385C;
  font-size: 32px;
  font-weight: 600;
}

.box__content > p {
  color: #333333;
  line-height: 1.5;
  font-weight: 300;
  font-size: 20px;
  font-weight: 600;
}

.box__social {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 20px;
}

.box__social > i {
  background-color: #586295;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #bdc6d0;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.pay-text {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #333333;
  margin-top: 40px;
}